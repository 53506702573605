import Address from "@/constants/address.js";
import { Query } from "@/services/utils/validators.js";
import { Requests } from "./requests";

// Classes
import Modules from "./moduleFactory/index";

// Modules
import Auth from "@/services/moduleFactory/modules/auth.js";
import Balance from "@/services/moduleFactory/modules/balance.js";
import Budget from "@/services/moduleFactory/modules/budget.js";
import CashFlow from "@/services/moduleFactory/modules/cashflow";
import ChartData from "@/services/moduleFactory/modules/chartData.js";
import Client from "@/services/moduleFactory/modules/client";
import Debts from "@/services/moduleFactory/modules/debts.js";
import FutureFlow from "@/services/moduleFactory/modules/futureFlow.js";
import HelpDesk from "@/services/moduleFactory/modules/helpDesk.js";
import Integration from "@/services/moduleFactory/modules/integration";
import Investments from "@/services/moduleFactory/modules/investments.js";
import ManagingPartner from "@/services/moduleFactory/modules/managingPartner";
import Me from "@/services/moduleFactory/modules/me";
import MyAssets from "@/services/moduleFactory/modules/myAssets.js";
import Notices from "@/services/moduleFactory/modules/notices.js";
import Notification from "@/services/moduleFactory/modules/notification.js";
import Onboarding from "@/services/moduleFactory/modules/onboarding.js";
import Organization from "@/services/moduleFactory/modules/organization";
import Origin from "@/services/moduleFactory/modules/origin.js";
import Payment from "@/services/moduleFactory/modules/payment";
import Pendency from "@/services/moduleFactory/modules/pendency.js";
import Profiles from "@/services/moduleFactory/modules/profiles";
import Recaptcha from "@/services/moduleFactory/modules/recaptcha.js";
import Reminder from "@/services/moduleFactory/modules/reminder.js";
import RestAuth from "@/services/moduleFactory/modules/rest-auth";
import Tasks from "@/services/moduleFactory/modules/tasks";
import OpenBankingV2 from "@/services/moduleFactory/modules/openBankingV2";
import Bank from "@/services/moduleFactory/modules/bank";
import CreditCard from "@/services/moduleFactory/modules/creditCard";
import Logging from "@/services/moduleFactory/modules/log";
import Tour from "@/services/moduleFactory/modules/tour";

// OrientedModules
import _Transactions from "@/services/moduleFactory/modules/_transactionOriented";
import CustomEmailService from "@/services/moduleFactory/modules/customEmail";
import PlannerChat from "@/services/moduleFactory/modules/plannerChat";
import Billing from "@/services/moduleFactory/modules/billing";
import Insights from "@/services/moduleFactory/modules/insights";

/**
 * @obs To add a new module, just use @template.js
 *
 * When you finish creating and importing your new module, add it down here.
 * Once adding a new module, it will be accessible using the find method.
 *
 * @example
 * Mod.find(moduleName: string, moduleRoute: string): Request
 */
const Mod = new Modules([
   Auth,
   Budget,
   CashFlow,
   ChartData,
   Client,
   FutureFlow,
   HelpDesk,
   Integration,
   ManagingPartner,
   Me,
   MyAssets,
   Organization,
   Payment,
   Profiles,
   RestAuth,
   Tasks,
   Notification,
   Notices,
   Pendency,
   Origin,
   Recaptcha,
   Reminder,
   Onboarding,
   Debts,
   Balance,
   Investments,
   _Transactions,
   OpenBankingV2,
   CustomEmailService,
   Bank,
   PlannerChat,
   Billing,
   CreditCard,
   Tour,
   Logging,
   Insights
]);

export { Mod };

/**
 * @deprecated
 * These constants are deprecated and are used by a deprecated class called Api
 * The Api class is deprecated and should be removed when possible
 *
 * @note It is being used by old store request
 */

const bulkCashFlow = "cash-flows/";
const budget = "budget/";
const budgetItem = "budget-item/";
const budgetTask = "tasks/budget-planning/";
const cashflow = "cash-flow/";
const cashflowMandatoryCategory = "category/recommended/";
const categoryList = "category/";
const searchCep = "https://webmaniabr.com/api/1/cep/";
const changePassword = "change-password/";
const changePasswordEmail = "change-password-email/";
const descriptionList = "description/";
const editProfile = "profiles/edit/";
const file = "file/";
const filteredCashflowByDate = "cash-flow/";
const finishBudget = "tasks/task/";
const finishClientRegister = "profiles/clients/finish-register/";
const importBundle = "import-bundle/";
const me = "me/";
const origins = "origin/";
const organization = "organization/";
const prospects = "prospect-bundle/";
const registerCompany = "organization/";
const task = "tasks/";
const taskImportBundle = "import/";
const taskList = "task/";
const taskUpdate = "/change-status/";
const upload = "upload/";
const uploadToBundle = "import-bundle/";

class Api {
   /**
    * @deprecated
    * This class and all its methods are deprecated.
    * Use Mod.find instead of Api.function
    *
    * @obs
    * To learn how to use Mod, go to
    * src\services\moduleFactory\modules\@template.js
    */

   /**
    * @deprecated
    * @param {object} budgetConfig The request config
    * @returns {Promise}
    */
   static addBudgetItem(budgetConfig) {
      return Requests.post({
         url: budgetItem,
         body: { ...budgetConfig }
      });
   }

   /**
    * @deprecated
    * @param {object} transactionData The request config
    * @returns {Promise}
    */
   static addTransaction(transactionData) {
      return Requests.post({
         url: cashflow,
         body: transactionData
      });
   }

   /**
    * @deprecated
    * @param {object} clientData The request config
    * @returns {Promise}
    */
   static bulkEditTransaction(clientData) {
      return Requests.put({
         url: bulkCashFlow + "?client=" + clientData.id,
         body: clientData.transactions
      });
   }

   /**
    * @deprecated
    * @param {object} list The request config
    * @returns {Promise}
    */
   static bulkRemoveTransactions(list) {
      return Requests.delete({
         url: bulkCashFlow,
         body: list
      });
   }

   /**
    * @deprecated
    * @param {object} clientData The request config
    * @returns {Promise}
    */
   static bulkSendTransactions(clientData) {
      return Requests.POST(
         {
            url: bulkCashFlow,
            body: clientData.transactions
         },
         {
            201: "Dados criados",
            default: "Erro inesperado"
         }
      );
   }

   /**
    * @deprecated
    * @param {object} cardData The request config
    * @returns {Promise}
    */
   static changeCardStatus(cardData) {
      return Requests.put({
         url: task + taskList + cardData.taskID + taskUpdate,
         body: {
            status: cardData.status
         }
      });
   }

   /**
    * @deprecated
    * @param {object} newPassword The request config
    * @returns {Promise}
    */
   static changePassword(newPassword) {
      return Requests.post({
         url: changePassword,
         headers: false,
         body: newPassword
      });
   }

   /**
    * @deprecated
    * @param {object} taskData The request config
    * @returns {Promise}
    */
   static createTask(taskData) {
      let type = `${taskData.type}/`;
      return Requests.post({
         url: task + type,
         body: {
            ...taskData
         }
      });
   }

   /**
    * @deprecated
    * @param {object} requestedData The request config
    * @returns {Promise}
    */
   static creditCardUpdate(requestedData) {
      return Requests.post({
         url: finishClientRegister,
         headers: requestedData.headers,
         body: { ...requestedData.body }
      });
   }

   /**
    * @deprecated
    * @param {number} budgetItemID The request config
    * @returns {Promise}
    */
   static deleteBudgetItem(budgetItemID) {
      return Requests.delete({
         url: budgetItem + budgetItemID.id + "/"
      });
   }

   /**
    * @deprecated
    * @param {object} budgetItemID The request config
    * @returns {Promise}
    */
   static deleteBudgetItemList(budgetItemID) {
      return Requests.put({
         url: budget + budgetItemID.budget + "/delete-items/",
         body: budgetItemID.id
      });
   }

   /**
    * @deprecated
    * @param {number} fileID The request config
    * @returns {Promise}
    */
   static deleteFile(fileID) {
      return Requests.delete({
         url: `${file + fileID}/`
      });
   }

   /**
    * @deprecated
    * @param {object} cashflowData The request config
    * @returns {Promise}
    */
   static doSaveCashflow(cashflowData) {
      const query = cashflowData.client ? `?clientId=${cashflowData.client}` : "";
      return Requests.put({
         url: cashflow + cashflowData.id + "/" + query,
         body: cashflowData.data
      });
   }
   /**
    * @deprecated
    * @param {object} profileData The request config
    * @returns {Promise}
    */
   static editProfile(profileData) {
      return Requests.post({
         url: editProfile,
         body: {
            first_name: profileData.firstName,
            last_name: profileData.lastName,
            phone: profileData.phone,
            ...(!!profileData.profile_picture && {
               profile_picture: profileData.profile_picture
            })
         }
      });
   }

   /**
    * @deprecated
    * @param {object} requestQuery The request config
    * @returns {Promise}
    */
   static fetchBudget(requestQuery) {
      let query = `?cashDateStart=${requestQuery.start}&cashDateEnd=${requestQuery.end}`;
      query += requestQuery.client ? `&clientId=${requestQuery.client}` : "";

      return Requests.get({
         url: filteredCashflowByDate + "summary" + "/" + query
      });
   }

   /**
    * @deprecated
    * @param {number | string} budgetID The request config
    * @returns {Promise}
    */
   static fetchBudgetComparison(budgetID) {
      return Requests.get({
         url: budget + budgetID + "/comparison"
      });
   }

   /**
    * @deprecated
    * @returns {Promise}
    */
   static fetchBudgetPlans() {
      return Requests.get({
         url: budgetTask + "?status=done"
      });
   }

   /**
    * @deprecated
    * @param {object} cardData The request config
    * @returns {Promise}
    */
   static fetchTasksType(cardData) {
      const hasClientId = cardData.clientID !== undefined ? `&client=${cardData.clientID}` : "";
      return Requests.get({
         url: task + taskList + "?status=" + cardData.status + hasClientId
      });
   }

   /**
    * @deprecated
    * @param {object} requestData The request config
    * @returns {Promise}
    */
   static fetchUser(requestData) {
      return Requests.get({
         url: me,
         ...requestData
      });
   }

   /**
    * @deprecated
    * @param {string} cep The request config
    * @returns {Promise}
    */
   static findCep(cep) {
      return Requests.custom({
         method: "GET",
         url:
            searchCep +
            `${cep}/?app_key=${Address.CEP.app_key}&app_secret=${Address.CEP.app_secret}`,
         customUrl: true
      });
   }

   /**
    * @deprecated
    * @param {object} filterOptions The request config
    * @returns {Promise}
    */
   static filterCashflow(filterOptions) {
      if (!filterOptions.start) return;
      let query = `?cashDateStart=${filterOptions.start}&cashDateEnd=${filterOptions.end}`;

      if (filterOptions.client) {
         query += `&clientId=${filterOptions.client}`;
      }
      if (filterOptions?.origins?.length) {
         query += `&origins=[${filterOptions.origins}]`;
      }
      return Requests.get({
         url: filteredCashflowByDate + query
      });
   }

   /**
    * @deprecated
    * @param {object} taskData The request config
    * @returns {Promise}
    */
   static finishBudgetPlan(taskData) {
      return Requests.put({
         url: `${finishBudget + taskData.task}/change-status/`,
         body: { status: taskData.status }
      });
   }

   /**
    * @deprecated
    * @param {number | string} budgetID The request config
    * @param transferer
    * @returns {Promise}
    */
   static getBudgetPlan(budgetID, transferer) {
      return Requests.get({
         url: `${budget + budgetID}?asset=${transferer ? "True" : "False"}`
      });
   }

   /**
    * @deprecated
    * @param {number | string} budgetID The request config
    * @returns {Promise}
    */
   static getBudgetTask(budgetID) {
      return Requests.get({
         url: budgetTask + budgetID + "/"
      });
   }

   /**
    * @deprecated
    * @returns {Promise}
    */
   static getCategories() {
      return Requests.get({
         url: categoryList
      });
   }

   /**
    * @deprecated
    * @param {number | string} taskID The request config
    * @returns {Promise}
    */
   static getCategorizingDates(taskID) {
      return Requests.get({
         url: task + "categorizing/" + taskID + "/"
      });
   }

   /**
    * @deprecated
    * @returns {Promise}
    * @param clientData
    */
   static getClientFileList(clientData) {
      const clientId = {
         clientId: clientData.clientID
      };
      const query = Query.build(clientId);
      return Requests.get({
         url: importBundle + clientData.bundleID + "/" + query
      });
   }

   /**
    * @deprecated
    * @param {number | string} activeClient The request config
    * @returns {Promise}
    */
   static getClientList(activeClient) {
      let link = "profiles/clients/";
      let query = "?isActive=" + !!activeClient;

      return Requests.get({
         url: link + query
      });
   }

   /**
    * @deprecated
    * @returns {Promise}
    */
   static getDescription() {
      return Requests.get({
         url: descriptionList
      });
   }

   /**
    * @deprecated
    * @returns {Promise}
    * @param transactionData
    */
   static getDescriptionTag(transactionData) {
      return Requests.get({
         url: `${descriptionList}?tags=${transactionData.query}&is_expense=${transactionData.isExpense}`
      });
   }
   /**
    * @deprecated Being used in the login
    * @returns {Promise}
    */
   static getMe() {
      return Requests.get({
         url: me
      });
   }

   /**
    * @deprecated
    * @param {number | string} descriptionID The request config
    * @returns {Promise}
    */
   static getMandatoryCategoryList(descriptionID) {
      return Requests.get({
         url: cashflowMandatoryCategory + `?description=${descriptionID}`
      });
   }

   /**
    * @deprecated
    * @returns {Promise}
    */
   static getMyTasks() {
      return Requests.get({
         url: task + taskList + "?status=pending"
      });
   }

   /**
    * @deprecated
    * @returns {Promise}
    * @param query
    */
   static getOrigins(query) {
      query = query || "";
      return Requests.get({
         url: origins + query
      });
   }

   /**
    * @deprecated
    * @returns {Promise}
    */
   static getProspects() {
      return Requests.get({
         url: prospects
      });
   }

   /**
    * @deprecated
    * @param {number | string} id The request config
    * @returns {Promise}
    */
   static getSelectedFile(id) {
      return Requests.get({
         url: file + id + "/read/?ignored=false"
      });
   }

   /**
    * @deprecated
    * @param {number | string} bundleID The request config
    * @returns {Promise}
    */
   static getTaskImportBundle(bundleID) {
      return Requests.get({
         url: task + taskImportBundle + `${bundleID}/`
      });
   }

   /**
    * @deprecated
    * @param {string} organizationName The request config
    * @returns {Promise}
    */
   static org(organizationName) {
      return Requests.get({
         url: organization + `${organizationName}/`
      });
   }

   /**
    * @deprecated
    * @param {object} data The request config
    * @returns {Promise}
    */
   static registerCompany(data) {
      const { header } = data;

      return Requests.post({
         url: registerCompany,
         headers: { ...header },
         body: data
      });
   }

   /**
    * @deprecated
    * @param {string | number} taskID The request config
    * @returns {Promise}
    */
   static removeTask(taskID) {
      return Requests.delete({
         url: task + taskList + taskID + "/"
      });
   }

   /**
    * @deprecated
    * @param {object} emailRequested The request config
    * @returns {Promise}
    */
   static requestNewPassword(emailRequested) {
      return Requests.post({
         url: changePasswordEmail,
         headers: false,
         body: emailRequested
      });
   }

   /**
    * @deprecated
    * @param {object} budgetData The request config
    * @returns {Promise}
    */
   static saveBudgetItem(budgetData) {
      const { ideal } = budgetData;
      let url =
         ideal || !budgetData.budget
            ? `budget-ideal/ideal-item/${budgetData.id}`
            : budgetItem + budgetData.id + "/";
      delete budgetData.ideal;
      let body = budgetData;
      delete body.transfer;
      return Requests.put({
         url,
         body
      });
   }

   /**
    * @deprecated
    * @returns {Promise}
    * @param order
    */
   static sendEmail(order) {
      return Requests.post({
         url: order.url,
         ...(order.header && {
            headers: { ...order.header }
         }),
         ...(order.body && {
            body: { ...order.body }
         })
      });
   }
   /**
    * @deprecated
    * @param {object} data The request config
    * @returns {Promise}
    */
   static updateCompany(data) {
      const { header, slug, ...body } = data;

      return Requests.put({
         url: registerCompany + `${slug}/`,
         headers: { ...header },
         body
      });
   }

   /**
    * @deprecated
    * @returns {Promise}
    * @param uploadData
    */
   static upload(uploadData) {
      const query = Query.build(uploadData.clientId);
      return Requests.upload(
         {
            url: upload + query,
            body: uploadData.formData,
            headers: {
               "Content-Type": "application/x-www-form-urlencoded"
            }
         },
         uploadData.progress
      );
   }

   /**
    * @deprecated
    * @param {object} uploadData The request config
    * @returns {Promise}
    */
   static uploadProspect(uploadData) {
      return Requests.upload(
         {
            url: prospects,
            body: uploadData.formData,
            headers: {
               "Content-Type": "application/x-www-form-urlencoded"
            }
         },
         uploadData.progress
      );
   }

   /**
    * @deprecated
    * @param {object} uploadData The request config
    * @returns {Promise}
    */
   static uploadToBundle(uploadData) {
      return Requests.upload(
         {
            url: uploadToBundle + uploadData.bundleID + "/add/",
            body: uploadData.formData,
            headers: {
               "Content-Type": "application/x-www-form-urlencoded"
            }
         },
         uploadData.progress
      );
   }
}

export default Api;
