import { Mod } from "@/services/api";

export default {
   state: {
      insights: []
   },

   mutations: {
      SET_INSIGHTS(state, insights) {
         state.insights = insights;
      }
   },

   actions: {
      async getInsights({ commit }, { clientId }) {
         try {
            const insights = await Mod.find("Insights", "list").get(clientId);
            commit("SET_INSIGHTS", insights.data.data);
            return insights.data.data;
         } catch (e) {
            console.error(e); // eslint-disable-line
            throw new Error(e);
         }
      }
   }
};
